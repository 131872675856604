import React from "react";
import "./Datatable.scss";
import { useTable, useBlockLayout, useResizeColumns } from "react-table";

const DataTable = (props) => {
  const data = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => props.columns, [props.columns]);
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useBlockLayout,
      useResizeColumns
    );

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead style={{ position: "sticky", top: 0, zIndex: 11 }}>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                const isSticky = column.isSticky;
                const isStickyRight = column.isStickyRight;

                const stickyStyle = isSticky
                  ? {
                      position: "sticky",
                      left: headerGroup.headers
                        .slice(0, index)
                        .reduce((acc, curr) => (curr.isSticky ? acc + curr.width : acc), 0),
                      zIndex: 10,
                      backgroundColor: "#fff",
                    }
                  : isStickyRight
                  ? {
                      position: "sticky",
                      right: headerGroup.headers
                        .slice(index + 1)
                        .reduce((acc, curr) => (curr.isStickyRight ? acc + curr.width : acc), 0),
                      zIndex: 10,
                      backgroundColor: "#fff",
                    }
                  : {
                      backgroundColor: "#fff",
                      position: "relative",
                      zIndex: 1,
                    };

                return (
                  <th
                    key={column.id}
                    {...column.getHeaderProps()}
                    style={{
                      ...stickyStyle,
                      width: column.width,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    {column.render("Header")}
                    {(column.Header || index === 0) && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  const isSticky = cell.column.isSticky;
                  const isStickyRight = cell.column.isStickyRight;

                  const stickyCellStyle = isSticky
                    ? {
                        position: "sticky",
                        left: headerGroups[0].headers
                          .slice(0, index)
                          .reduce((acc, curr) => (curr.isSticky ? acc + curr.width : acc), 0),
                        zIndex: 10,
                      }
                    : isStickyRight
                    ? {
                        position: "sticky",
                        right: headerGroups[0].headers
                          .slice(index + 1)
                          .reduce((acc, curr) => (curr.isStickyRight ? acc + curr.width : acc), 0),
                        zIndex: 10,
                      }
                    : {};

                  return (
                    <td
                      key={cell.row.id}
                      {...cell.getCellProps()}
                      style={{
                        ...stickyCellStyle,
                        width: cell.column.width,
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default DataTable;
