import "./Category.scss";

import { Checkbox, Line } from "../../ui";
import { useState } from "react";

function Category({
  type,
  amount,
  checked = true,
  disabled = false,
  handleClick,
  withoutCheckbox = false,
  current_item,
  active = false
}) {
  const [isActive, setIsActive] = useState(checked);
  const handleChange = () => {
    setIsActive((prev) => {
      handleClick(!prev);
      return !prev;
    });
  };
  return (
    <div className="Category">
      {disabled ? <Line variant={"none"}/> : <Line variant={current_item?.key}/>}
      {!withoutCheckbox && !disabled && (
        <Checkbox onChange={handleChange} value={isActive} />
      )}
      <div className="main">
        <div className="type">{type}</div>
        {active && (<div className="amount">{amount}</div>)}
      </div>
    </div>
  );
}

export default Category;
