import { useEffect, useState } from "react";
import "./Campaings.scss";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import useAdminUser from "../../../data/useAdminUser";
import { format as dateFormat } from "date-fns";
import {
  filterId,
  filterBet,
  filterDate,
  filterDay,
  filterDecrease,
  filterFull,
  filterName,
  filterRange,
  filterSite,
  filterEmail,
  filterStatus,
  filterType,
  sortCampaings,
  INIT_FILTER_VALUE,
} from "./filters";
import DataTable from "../Table/Datatable";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { API_HOST } from "../../../configs/main";
import SortPopup from "../SortPopUp/SortPopup";
// import EditIcon from "@mui/icons-material/Edit";
import EditModal from "./EditModal";
import EditStatusModal from "./EditStatusModal";
import DeleteModal from './DeleteModal';
import { CtrViewModal } from './CtrModal';

function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}

const STATUS_TYPE = {
  INACTIVE: "Неактивна",
  WORK: "Работает",
  MODERATION: "На модерации",
  ERROR: "Ошибка",
};

const TARGET_TYPE = {
  TRAFFIC: "Трафик",
  REACH: "Охват",
};

function Campaigns() {
  const { enqueueSnackbar } = useSnackbar();
  const [filterArr, setFilter] = useState([]);
  const [campaignsArr, setCampaignsArr] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [ctrViewModal, setCtrViewModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [current, setCurrent] = useState();
  const { role } = useAdminUser();
  const [upd, setUpd] = useState(false)
  // const [inp, setInp] = useState({
  //     range: "",
  //     full_budget: "",
  //     daily_budget: "",
  //     decrease: "",
  //     type: "",
  //     status: "",
  //     site: "",
  //     id: "",
  //     name: "",
  //     bet: "",
  // });
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/campaigns`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
      });
      if (res.ok) {
        const data = await res.json();
        setFilter(data);
        setValue((prev) => ({ ...prev, sort: "created_at" }));
        setCampaignsArr(data);
      }
    }
    fetchData();
  }, [upd, isDeleteModal]);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "id":
            if (value === "") return acc;
            return filterId(value, acc);
          case "name":
            if (value === "") return acc;
            return filterName(value, acc);
          case "site":
            if (value === "") return acc;
            return filterSite(value, acc);
          case "campaign_creator_email":
            if (value === "") return acc;
            return filterEmail(value, acc);
          case "created_at":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "type":
            if (value === "") return acc;
            return filterType(value, acc);
          case "daily_budget":
            if (value === "") return acc;
            return filterDay(value, acc);
          case "full_budget":
            if (value === "") return acc;
            return filterFull(value, acc);
          case "bet":
            if (value === "") return acc;
            return filterBet(value, acc);
          case "range":
            if (value === "") return acc;
            return filterRange(value, acc);
          case "decrease":
            if (value === "") return acc;
            return filterDecrease(value, acc);
          case "status":
            if (value === "") return acc;
            return filterStatus(value, acc);
          case "sort":
            if (value === "") return acc;
            return sortCampaings(value, acc);
          default:
            return acc;
        }
      },
      [...campaignsArr]
    );
    setFilter(result);
  }, [filtersValue, campaignsArr]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = [
    {
      Header: "ID РК",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Название",
      accessor: "name",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сайт",
      accessor: "site",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Почта клиента",
      accessor: "campaign_creator_email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Дата создания",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Тип РК",
      accessor: "type",
      maxWidth: 400,
      minWidth: 60,
      width: 117,
    },
    {
      Header: "Дневной бюджет",
      accessor: "daily_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Общий бюджет",
      accessor: "full_budget",
      maxWidth: 400,
      minWidth: 80,
      width: 145,
    },
    {
      Header: "Ставка",
      accessor: "bet",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
    {
      Header: "Период с-по",
      accessor: "range",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "Сумма спис",
      accessor: "charge",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 50,
      width: 200,
    },
    {
      Header: "CTR",
      accessor: "ctr",
      maxWidth: 360,
      minWidth: 50,
      width: 100,
    },
    {
      Header: "Удаление РК",
      accessor: "delete",
      maxWidth: 360,
      minWidth: 50,
      width: 100,
    },
  ];
  function getTableData() {
    return filterArr?.map((el) => {
      return {
        // edit: (
        //   <EditIcon
        //     onClick={() => {
        //       setEditModal(true);
        //       setCurrent(el);
        //     }}
        //     style={{ cursor: "pointer" }}
        //     fontSize="small"
        //   />
        // ),
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "row", borderRadius: 5 }}
          >
            {el.id.slice(0, 8)}
            <ContentCopy
              style={{ marginLeft: 5, cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(el.id);
                enqueueSnackbar("ID кампании скопирован", {
                  variant: "success",
                  autoHideDuration: 1000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
              }}
              className="copyIcon"
              fontSize="small"
            />
          </div>
        ),
        name: <div className="td">{el.name ? el.name : "-"}</div>,
        site: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.site ? el.site : "-"}</span>
            {el.site && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.site);
                  enqueueSnackbar("Сайт кампании скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        campaign_creator_email: (
          <div className="td" style={{ display: "flex", flexDirection: "row" }}>
            <span>{el.campaign_creator_email ? el.campaign_creator_email : "-"}</span>
            {el.campaign_creator_email && (
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.campaign_creator_email);
                  enqueueSnackbar("Почта клиента скопирована", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            )}
          </div>
        ),
        created_at: (
          <div className="td">
            {dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")}
          </div>
        ),
        type: <div className="td">{TARGET_TYPE[el.target_type]}</div>,
        daily_budget: <div className="td">{el.daily_budget}</div>,
        full_budget: <div className="td">{el.full_budget}</div>,
        bet: <div className="td">{el.price_per_action}</div>,
        range: (
          <div className="td">
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_start)),
              "dd/MM/yyyy"
            )}
            -
            {dateFormat(
              Date.parse(new Date(el.validity_period.date_end)),
              "dd/MM/yyyy"
            )}
          </div>
        ),
        charge: <div className="td">{el.charges_sum}</div>,
        status: (
          <div className="td">
            {STATUS_TYPE[el.status]}{" "}
            {["ADMIN", "SUPERUSER"].some((el) => el === role) && !['ERROR','INACTIVE'].includes(el.status) && (
              <svg
                onClick={() => {
                  setCurrent(el);
                  setStatusModal(true);
                }}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
        ),
        ctr:  <button
                onClick={() => {setCurrent(el); setCtrViewModal(true)}}
                style={{padding: "5px 10px", fontSize: "12px", fontWeight: "500", borderRadius: 5, background: "#DFE8FF", color: "#3F73F9"}}>
                Изменить 
              </button>,

        delete: <div onClick={() => {
          setCurrent(el); setDeleteModal(true);
        }} style={{padding: "5px", cursor: "pointer", opacity: ".8"}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#0d0d0d" viewBox="0 0 256 256"><path d="M216,48H180V36A28,28,0,0,0,152,8H104A28,28,0,0,0,76,36V48H40a12,12,0,0,0,0,24h4V208a20,20,0,0,0,20,20H192a20,20,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM100,36a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V48H100Zm88,168H68V72H188ZM116,104v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z"></path></svg></div>
      };
    });
  }

  const downloadFile = async () => {
    const myHeaders = new Headers();
    const filters = {
      id: filtersValue.id ? filtersValue.id : undefined,
      name: filtersValue.name ? filtersValue.name : undefined,
      site: filtersValue.site ? filtersValue.site : undefined,
      campaign_creator_email: filtersValue.campaign_creator_email ? filtersValue.campaign_creator_email : undefined,
      full_budget: {
        from: filtersValue.full_budget.start
          ? +filtersValue.full_budget.start
          : undefined,
        to: filtersValue.full_budget.end
          ? +filtersValue.full_budget.end
          : undefined,
      },
      daily_budget: {
        from: filtersValue.daily_budget.start
          ? +filtersValue.daily_budget.start
          : undefined,
        to: filtersValue.daily_budget.end
          ? +filtersValue.daily_budget.end
          : undefined,
      },
      decrease: {
        from: filtersValue.decrease.start
          ? +filtersValue.decrease.start
          : undefined,
        to: filtersValue.decrease.end ? +filtersValue.decrease.end : undefined,
      },
      type: filtersValue.type ? filtersValue.type : undefined,
      status: filtersValue.status ? filtersValue.status : undefined,
      bet: filtersValue.bet ? filtersValue.bet : undefined,
    };

    let data = removeEmpty(filters);
    if (filtersValue.created_at.date_start) {
      data = {
        ...data,
        created_at: {
          from: filtersValue.created_at.date_start,
        },
      };
    }
    if (filtersValue.created_at.date_end) {
      data = {
        ...data,
        created_at: {
          ...data.created_at,
          to: filtersValue.created_at.date_end,
        },
      };
    }
    if (filtersValue.range.date_start) {
      data = {
        ...data,
        range: {
          from: filtersValue.range.date_start,
        },
      };
    }
    if (filtersValue.range.date_end) {
      data = {
        ...data,
        range: {
          ...data.range,
          to: filtersValue.range.date_end,
        },
      };
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const response = await fetch(
      `${API_HOST}/api/v1/admin/campaigns/download`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      const link = document.createElement("a");
      let filename = response.headers
        ?.get("content-disposition")
        .split("filename=")[1];
      const blob = await response.blob();
      if (filename === null) filename = "Clients.csv";
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSortChange = (item) => {
    setValue((prev) => ({ ...prev, sort: item.type }));
  };
  const handleCreatedAtChange = (value) => {
    setValue((prev) => ({
      ...prev,
      created_at: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };
  const handleRangeChange = (value) => {
    setValue((prev) => ({
      ...prev,
      range: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  return (
    <div className="campaings" style={{ width: "100%", paddingLeft: 0, height: "95%" }}>
      <div className="filters">
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, id: e.target.value }))
          }
          className="searchId"
          placeholder="ID РК"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, name: e.target.value }))
          }
          className="searchEmail"
          placeholder="Название"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, site: e.target.value }))
          }
          className="searchSite"
          placeholder="Сайт"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, campaign_creator_email: e.target.value }))
          }
          className="searchEmail"
          placeholder="Почта клиента"
        />
        <div className="searchDate">
          <span className="DateSpan">Дата Создание</span>
          <DateFromTo
            withoutStartDate={true}
            handleChange={handleCreatedAtChange}
          />
        </div>
        <Box sx={{ width: 150 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Тип кампании
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Фактические пополнения/Списания/Неоплаченные"
              id="demo-select-small-label"
              value={filtersValue.type}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, type: e.target.value }))
              }
            >
              <MenuItem value={""}>Все кампании</MenuItem>
              <MenuItem value={"REACH"}>Охват</MenuItem>
              <MenuItem value={"TRAFFIC"}>Трафик</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <div className="searchRange">
          <span className="span">Дневной бюджет</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  daily_budget: { ...prev.daily_budget, start: e.target.value },
                }))
              }
              value={filtersValue.daily_budget.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  daily_budget: { ...prev.daily_budget, end: e.target.value },
                }))
              }
              value={filtersValue.daily_budget.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <div className="searchRange">
          <span className="span">Общий бюджет</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  full_budget: { ...prev.full_budget, start: e.target.value },
                }))
              }
              value={filtersValue.full_budget.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  full_budget: { ...prev.full_budget, end: e.target.value },
                }))
              }
              value={filtersValue.full_budget.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <div className="searchDate">
          <span
            style={{
              position: "absolute",
              textAlign: "center",
              width: "460px",
              top: 117,
            }}
          >
            Период действия
          </span>
          <DateFromTo
            withoutStartDate={true}
            handleChange={handleRangeChange}
          />
        </div>
        <div className="searchRange">
          <span className="span">Ставка</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  bet: { ...prev.bet, start: e.target.value },
                }))
              }
              value={filtersValue.bet.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  bet: { ...prev.bet, end: e.target.value },
                }))
              }
              value={filtersValue.bet.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <div className="searchRange">
          <span className="span">Сумма списаний по РК</span>
          <div className="inpRange">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  decrease: { ...prev.decrease, start: e.target.value },
                }))
              }
              value={filtersValue.decrease.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  decrease: { ...prev.decrease, end: e.target.value },
                }))
              }
              value={filtersValue.decrease.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <Box style={{ marginTop: 1 }} sx={{ width: 240 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Статус
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Статус счета/онлайн-оплаты"
              id="demo-select-small-label"
              value={filtersValue.status}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, status: e.target.value }))
              }
            >
              <MenuItem value={""}>Все статусы</MenuItem>
              <MenuItem value={"INACTIVE"}>Неактивна</MenuItem>
              <MenuItem value={"WORK"}>Работает</MenuItem>
              <MenuItem value={"MODERATION"}>На модерации</MenuItem>
              <MenuItem value={"ERROR"}>Ошибка</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="downald">
        <button onClick={() => downloadFile()} type="button">
          Скачать CSV
        </button>
      </div>
      <SortPopup
        sortedBy={true}
        title="Сортировать по"
        items={[
          { name: "дате создания", type: "created_at" },
          { name: "общему бюджету", type: "full_budget" },
          { name: "дневному бюджету", type: "daily_budget" },
        ]}
        onChange={handleSortChange}
      />
      <div style={{ overflowX: "auto", width: "100%", height: "73%", overflowY: "auto" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <EditModal
        modal={editModal}
        campaing={current}
        handleCloseModal={() => setEditModal(false)}
      />
      <EditStatusModal
        modal={statusModal}
        handleCloseModal={() => setStatusModal(false)}
        campaing={current}
        upd={()=>setUpd(prev => !prev)}
      />
      <DeleteModal
        modal={isDeleteModal}
        handleCloseEdit={() => setDeleteModal(false)}
        campaing={current}
      />
      {ctrViewModal && (
        <CtrViewModal
          modal={ctrViewModal}
          handleCloseEdit={() => setCtrViewModal(false)}
          campaing={current}
        />
      )}
    </div>
  );
}
export default Campaigns;
