import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { updateCampaign } from "../../../redux/createNewCompany/actions";
import { LoadingButton } from "@mui/lab";
import "./Optimization.scss"

const INITIAL_VALUES = {
  optimization_target: "",
  // autobidding_mode: "",
  max_impressions: 0,
  fix_price: 0,
  min_consumption: 0,
  optimal_score: 0,
};

const INITIAL_VALUES_REACH = {
  optimization_target: "SHOWS",
  // autobidding_mode: "",
  max_impressions: 0,
  fix_price: 120,
  min_consumption: 0,
  optimal_score: 0,
};

const INITIAL_VALUES_TRAFFIC = {
  optimization_target: "CLICKS",
  // autobidding_mode: "",
  max_impressions: 0,
  fix_price: 42,
  min_consumption: 0,
  optimal_score: 0,
};

const createInitialValues = (data, target_type) => {
  if ((!data) && (target_type === "REACH")) return INITIAL_VALUES_REACH;
  if ((!data) && (target_type === "TRAFFIC")) return INITIAL_VALUES_TRAFFIC;
  const result = { ...INITIAL_VALUES };
  for (const key in result) {
    if (key in data) {
      result[key] = data[key];
    }
  }
  return result;
};

const DataEqual = (objA, objB) => {
  if (!objA || !objB) return false;
  const properties = ['fix_price'];
  for (let key of properties) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }
  return true;
};

const Optimization = (props) => {
  const dispatch = useDispatch();
  const handleChangeTab = () => props.handleChange("event", "5");
  //console.log("PROPS",props,"DATA",props.data)
  const { handleChange, values, handleSubmit, isSubmitting,errors } = useFormik({
    initialValues: createInitialValues(props.data?.optimization, props.data?.basic_config.target_type),
    validate: (values) => {
      let errors = {}
      if((values?.optimization_target === 'CLICKS') && (values.fix_price < 42)){
        errors.fix_price = "Значение должно быть больше либо равно 42"
      }
      if((values?.optimization_target === 'SHOWS') && (values.fix_price < 120)){
        errors.fix_price = "Значение должно быть больше либо равно 120"
      }
      return errors
    },
    onSubmit: async (values) => {
      if (!DataEqual(props.data.optimization, values)) {
        dispatch(
          updateCampaign(
            values,
            props.campaignID,
            "optimization",
            handleChangeTab
          )
        )
      }
      return handleChangeTab()
    },
  });

  return (
    <div className="optimization__wrapper" style={{ marginLeft: "20px" }}>
      <div className="title-text" style={{ marginBottom: "50px" }}>
        <h2>Оптимизация</h2>
        <p>3000+ Сегментов - Показывайте рекламу Только Тем, Кто Вам Нужен</p>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="center"
          justify="center"
        >
          <Grid className="optimItem1" item xs={4}>
            <p className="title tr"
              style={{
                marginTop: 10,
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Что оптимизируем
            </p>
            <FormControl sx={{ width: "100%" }}>
              {(props.data.basic_config.target_type === "REACH") ?
                <RadioGroup
                  aria-label="optimization_target"
                  name="optimization_target"
                  value={values.optimization_target}
                  onChange={handleChange}
                  sx={{
                    marginTop: "10px",
                  }}
                >

                  <div
                    style={{
                      color: "black",
                      marginLeft: "20px",
                    }}
                  >
                    <FormControlLabel
                      disabled={true}
                      value="SHOWS"
                      control={<Radio />}
                      label="Клики"
                      checked={!(props.data.basic_config.target_type === "REACH")}
                    />
                    <FormControlLabel
                      disabled={true}
                      value="SHOWS"
                      control={<Radio />}
                      checked={(props.data.basic_config.target_type === "REACH")}
                      label="Показы"
                    />
                  </div>
                </RadioGroup> :
                <RadioGroup
                  aria-label="optimization_target"
                  name="optimization_target"
                  value={values.optimization_target}
                  onChange={handleChange}
                  sx={{
                    marginTop: "10px",
                  }}
                >

                  <div
                  className="radioGroup"
                    style={{
                      color: "black",
                      marginLeft: "20px",
                    }}
                  >
                    <FormControlLabel
                      disabled={true}
                      // disabled={props?.data?.optimization ? true : false}
                      value="CLICKS"
                      control={<Radio />}
                      label="Клики"
                      checked={(props.data.basic_config.target_type === "TRAFFIC")}
                    />
                    <FormControlLabel
                      disabled={true}
                      value="SHOWS"
                      control={<Radio />}
                      label="Показы"
                      checked={(!props.data.basic_config.target_type === "TRAFFIC")}
                    />
                  </div>
                </RadioGroup>}

            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="center"
          justify="center"
        >
          <Grid className="optimItem2"  item xs={4}>
            <p
              className="title tr"
              style={{
                marginTop: 40,
                marginBottom: 14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              На что оптимизируем
            </p>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-label="autobidding_mode"
                name="autobidding_mode"
                // value='SECOND_PRICE_MEAN' //{values.autobidding_mode}
                // onChange={handleChange}
                defaultValue={'FIXED'}
                sx={{
                  marginTop: "10px",
                }}
              >
                <div
                className="radioGroup"
                  style={{
                    color: "black",
                    marginLeft: "20px",
                  }}
                >
                  <FormControlLabel
                    disabled={false}
                    value="SECOND_PRICE_MEAN"
                    control={<Radio />}
                    label="Максимальное число кликов"
                  />
                  <FormControlLabel
                    disabled={false}
                    value="FIXED"
                    control={<Radio />}
                    label="Фиксированная ставка"
                  />
                  <FormControlLabel
                    disabled={false}
                    value="SECOND_PRICE"
                    control={<Radio />}
                    label="Минимальный расход"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={4}>
            <p
            className="title"
              style={{
                marginTop: 14,
                marginBottom:14,
                height: 24,
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Бюджет
            </p>
          </Grid>
        </Grid>
        <Grid
          className="grid"
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
          rowSpacing={5}
          alignItems="flex-start"
          justify="center"
        >
          <Grid item xs={3}>
            <FormControl sx={{ width: "95%" }}>
              <TextField
                disabled={false}
                error={errors.fix_price}
                name="fix_price"
                onChange={handleChange}
                value={values.fix_price}
                label="Ставка"
                id="outlined-multiline-flexible"
                type="number"
                helperText={errors.fix_price ? errors.fix_price : <br/>}
              />
            </FormControl>
            <p className="br" style={{ marginBottom: 10, height: 24 }}></p>
          </Grid>

          <Grid className="br" item xs={3}></Grid>

          <Grid
            container
            sx={{
              marginTop: "20px",
              marginLeft: "25px",
            }}
          >
            <Grid item xs={4}>
              <Link to="/">
                <Button className="cancel" variant="outlined">Отмена</Button>
              </Link>
            </Grid>

            <Grid
              container
              item
              xs={8}
              style={{
                backgroundColor: "transparent",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isSubmitting}
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
                sx={{
                  marginLeft: "32px",
                }}
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? "Сохранение" : "Далее"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Optimization;
