import "./CardDashedGraph.scss";

import  { useMemo, useState, Fragment } from "react";
import {  Typography } from "../../ui";
import {  Category, RechartsDashed } from "../";

import {
  prepareIndicators,
  setFilterData,
} from "../../modules/Company/helpers";

//const ALL_YEAR_DATA = createAllYearData();
const LEGEND = [
  {
    key: "shows",
    name: "Показы",
    color: "#5dce5a",
    active: true,
  },
  {
    key: "reach",
    name: "Охваты",
    color: "#eeb55d",
    active: false,
  },
  {
    key: "clicks",
    name: "Клики",
    color: "#eeb55d",
    active: true,
  },
  {
    key: "leads",
    name: "Лиды",
    color: "#eeb55d",
    active: false,
  },
  {
    key: "spent",//
    name: "Расход (₽)",
    color: "#8dbfea",
    active: false,
  },
  {
    key: "ctr",
    name: "CTR %",
    color: "#a566d5",
    active: false,
  },
  {
    key: "cpc",
    name: "CPC (₽)",
    color: "#f99d9d",
    active: false,
  },
  {
    key: "ecpm",//
    name: "eCPM ( ₽)",
    color: "#f4e75e",
    active: false,
  },
  {
    key: "cr",//
    name: "CR (%)",
    color: "#77797a",
    active: false,
  },
  {
    key: "cpa",//
    name: "CPA",
    color: "#cccdcc",
    active: false,
  },
];
// const CATALOGS = [
//   {
//     title: "Все",
//   },
//   {
//     title: "Баннерные объявления",
//   },
//   {
//     title: "Native объявления",
//   },
//   {
//     title: "Мобильные объявления",
//   },
// ];
const CATEGORIES = [
  {
    type: "Показы",
    amount: (shows) => shows?.reduce((showsTotal, show) => showsTotal + show, 0),
    name: "shows",
    group: 1,
  },
  {
    type: "Охваты",
    amount: (reach) =>
      reach?.reduce((reachTotal, r) => reachTotal + r, 0),
    name: "reach",
    group: 1,
  },
  {
    type: "Клики",
    amount: (clicks) =>
      clicks?.reduce((clicksTotal, click) => clicksTotal + click, 0),
    name: "clicks",
    group: 1,
  },
  {
    type: "Лиды",
    amount: (leads) =>
      leads?.reduce((leadsTotal, lead) => leadsTotal + lead, 0),
    name: "leads",
    group: 1,
  },
  {
    type: "Расход",
    amount: (conversion) =>
      conversion?.length > 0
        ? (
            conversion.reduce(
              (conversionAvg, conversion) => conversionAvg + conversion,
              0
            ) / conversion.length
          ).toFixed(2)
        : 0,
    name: "spent",
    group: 1,
  },
  {
    type: "CTR",
    amount: (ctr) =>
      ctr?.length > 0
        ? (ctr.reduce((ctrAvg, ctr) => ctrAvg + ctr, 0) / ctr.length).toFixed(
            2
          ) + "%"
        : 0,
    name: "ctr",
    group: 1,
  },
  {
    type: "CPC (₽)",
    amount: (cpc) =>
      cpc?.length > 0
        ? (cpc.reduce((cpcAvg, cpc) => cpcAvg + cpc, 0) / cpc.length).toFixed(2)
        : 0,
    name: "cpc",
    group: 1,
  },
  {
    type: "eCPM (₽)",
    amount: (cpc) =>
      cpc?.length > 0
        ? (cpc.reduce((cpcAvg, cpc) => cpcAvg + cpc, 0) / cpc.length).toFixed(2)
        : 0,
    name: "ecpm",
    group: 1,
  },
  {
    type: "CR (%)",
    amount: (cpc) =>
      cpc?.length > 0
        ? (cpc.reduce((cpcAvg, cpc) => cpcAvg + cpc, 0) / cpc.length).toFixed(2)
        : 0,
    name: "cr",
    group: 1,
  },
  {
    type: "CPA",
    amount: (budget) =>
      budget?.reduce((budgetTotal, bdgt) => budgetTotal + bdgt, 0),
    name: "cpa",
    group: 1,
  },
];

function CardDashedGraph({
  title,
  chartData, //this is data from day
  categories = CATEGORIES,
  legend = LEGEND,
  type,
}) {
  const [indicators, setIndicators] = useState(() => prepareIndicators(legend));
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState([]);
  function countIndicators() {
    let kol = 0
    Object.keys(indicators).forEach(key => indicators[key] ? (kol++) : "")
    return kol
  }
  //const [currentGroup, setCurrentGroup] = useState(startActiveGroup);
  const filteredData = useMemo(() => setFilterData(date, chartData?.time_intervals), [date]);
  const filteredIndicators = useMemo(
    () => legend.filter((indicator) => indicators[indicator.key]),
    [indicators]
  );
  function data_change(data) {
    data = data.split('T')[0]
    let str = data.slice(8,10) + "/"+ data.slice(5,7) + "/" + data.slice(0,4)
    return str
  }
  let color = {
    key: "second"
  }
  function second_item(name) {
    let arr=[]
    Object.keys(indicators).forEach(key => (indicators[key]) ? arr.push(key) : "")
    if (arr[1]===name) return true
    return false
  }
  return (
    <div className="CardDashedGraph">
      <div className="header">
        {title && (
          <Fragment>
            <div className="title">
              <Typography variant="h2" color="dark-gray">
                Общая статистика
              </Typography>
              {(chartData?.time_intervals?.length === 0) ? "" : 
              <Typography variant="body1" color="dark-gray">
                Период ({data_change(chartData?.time_intervals[0][0])} - {data_change(chartData?.time_intervals[chartData?.time_intervals?.length -1][0])})
                {/* Период ({date[0].dateToString()}-{date[1].dateToString()}) */}
              </Typography>
              }
            </div>
          </Fragment>
        )}

        {/* <div className="interface">
          <div className="tabs">
            {catalogs.map((catalog) => (
              <Catalog key={catalog.title}>{catalog.title}</Catalog>
            ))}
          </div>
          <div className="datefromto">
          <DateFromTo
            handleChange={(value) => setDate(value)}
            from={fromDate ?? null}
            to={toDate ?? null}
          />
          </div>
          <Button variant="primary">
            Сохранить отчет
            <SaveIcon />
          </Button>
        </div> */}
      </div>
      <div className="main-block">
        <div className="graph">
          <RechartsDashed type={type} legend={filteredIndicators} data={chartData} />
        </div>
        <div className="category-list">
          {categories.map(({ type, amount, name }) => (
            <Category
              current_item={((countIndicators() > 1) && second_item(name)) ? color : ""}
              key={type}
              type={type}
              amount={amount(filteredData?.map((item) => item[date] ?? 0))}
              checked={indicators[name]}
              disabled={(!indicators[name] && (countIndicators() > 1)) ? true : false}
              handleClick={(value) =>
                setIndicators((prev) => {
                  const result = { ...prev, [name]: value };
                  return result;
                })
              }
              />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardDashedGraph;
