import { Box, Modal, Typography } from "@material-ui/core";
import { API_HOST } from "../../configs/main";
import "./EmailModal.scss";
// import { async } from "q";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none",
  outline: "none",
};

export default function EmailModal({ open, handleClose, email, err }) {
  const handleResend = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(`${API_HOST}/api/v1/auth/email/resend`, {
      method: "post",
      headers: myHeaders,
      body: JSON.stringify({
        email: email,
      }),
    });
    if (res.ok) {
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="email_modal" sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {err === "token"
            ? "Проверка email не прошла"
            : "Неопознанная ошибка валидации email"}
        </Typography>
        <div className="confirmButton" onClick={handleResend}>
          Отправить ссылку с подтверждением ещё раз
        </div>
      </Box>
    </Modal>
  );
}
