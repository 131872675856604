import { useState } from "react";
import DataTable from "../Table/Datatable";
import { format as dateFormat } from "date-fns";
import { useEffect } from "react";
import { API_HOST } from "../../../configs/main";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import "./Logs.scss";
import { 
  INIT_FILTER_VALUE,
  filterDate,
  filterEmploye,
  filterAction,
  filterId,
  filterDescription } from "./filters";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const ACTION_TYPE = {
  REPLENISHMENT_STATUS_CHANGE: "Изменение статуса пополнения",
  CSV_DOWNLOAD: "Выгрузка списков",
  CUSTOM_REPLENISHMENT: "Пополнение",
  CUSTOM_CHARGE: "Списание",
  SET_MANAGER: "Привязка сотрудника",
  CLIENT_BLOCK: "Блокировка клиента",
  EMPLOYEE_REGISTRATION: "Добавление сотрудника",
  SET_EMPLOYEE_ROLE: "Изменение прав сотрудников",
  EMPLOYEE_BLOCK: "Деактивация сотрудника",
};

const TARGET_TYPE = {
  CLIENT: "Клиенты",
  REPLENISHMENT: "Пополнения",
  CAMPAIGN: "Кампании",
  EMPLOYEE: "Сотрудники",
};

function Logs() {
  const [filterArr, setFilter] = useState([]);
  const [arrLogs, setLogs] = useState([]);
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);

  useEffect(() => {
    async function fetchData() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin_token")
      );
      const res = await fetch(`${API_HOST}/api/v1/admin/logs`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
      });
      if (res.ok) {
        const data = await res.json();
        setLogs(data);
        setFilter(data);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "full_name":
            if (value === "") return acc;
            return filterEmploye(value, acc);
          case "target_id_or_number":
            if (value === "") return acc;
            return filterId(value, acc);
          case "action":
            if (value === "") return acc;
            return filterAction(value, acc);
          case "description":
            if (value === "") return acc;
            return filterDescription(value, acc);
          default:
            return acc;
        }
      },
      [...arrLogs]
    );
    setFilter(result);
  }, [filtersValue, arrLogs]);

  const columns = [
    {
      Header: "Дата/Время",
      accessor: "dt",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сотрудник",
      accessor: "employee",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Действие",
      accessor: "action",
      maxWidth: 400,
      minWidth: 80,
      width: 300,
    },
    {
      Header: "Сущность (раздел)",
      accessor: "target_type",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ID/№",
      accessor: "target_id_or_number",
      maxWidth: 400,
      minWidth: 80,
      width: 110,
    },
    {
      Header: "Доп. информация",
      accessor: "description",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
  ];

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  function getTableData() {
    return filterArr.map((el) => {
      return {
        dt: (
          <div
            className="td"
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: 5,
            }}
          >
            {dateFormat(Date.parse(el.dt), "hh:mm dd/MM/yyyy")}
          </div>
        ),
        employee: <div className="td">{el.employee ? el.employee : "-"}</div>,
        action: (
          <div className="td">{el.action ? ACTION_TYPE[el.action] : "-"}</div>
        ),
        target_type: (
          <div className="td">
            {el.target_type ? TARGET_TYPE[el.target_type] : "-"}
          </div>
        ),
        target_id_or_number: (
          <div className="td">
            {el.target_id_or_number ? el.target_id_or_number.slice(0, 8) : "-"}
          </div>
        ),
        description: (
          <div className="td">{el.description ? el.description : "-"}</div>
        ),
      };
    });
  }
  return (
    <div className="logs" style={{ width: "100%", height: "97%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, full_name: e.target.value }))
          }
          className="searchEmploee"
          placeholder="Сотрудник"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, target_id_or_number: e.target.value }))
          }
          style={{width: "150px", marginTop: "5px"}}
          placeholder="ID РК"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, description: e.target.value }))
          }
          style={{width: "250px", marginTop: "5px"}}
          placeholder="Доп. информация"
        />
        <Box style={{ marginTop: 5 }} sx={{ width: 240 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Статус
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Статус счета/онлайн-оплаты"
              id="demo-select-small-label"
              value={filtersValue.action}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, action: e.target.value }))
              }
            >
              <MenuItem value={""}>Все статусы</MenuItem>
              {Object.keys(ACTION_TYPE).map(key => (
                <MenuItem key={key} value={key}>{ACTION_TYPE[key]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <div style={{ overflowX: "auto", width: "100%", overflowY: "auto", height: "94%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
    </div>
  );
}

export default Logs;
