/* eslint-disable react/display-name */
import { forwardRef } from "react";
import "./ErrorContainer.scss";
import { Fade } from "@mui/material";

const ErrorContainer = forwardRef(({ errors }, ref) => {
  return (
    <Fade in={!!Object.values(errors).length}>
      <div className="error-container" ref={ref}>
        {Object.values(errors).map((error, index) => (
          <div key={`${error}-${index}`} className="error-item">
            {error}
          </div>
        ))}
      </div>
    </Fade>
  );
});

export default ErrorContainer;
