import React from "react";
import "./SortPopup.scss";
import {useState, useRef} from "react";

export default function SortPopup({sortedBy, title, items, onChange}) {
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [activeAsc, setActiveAsc] = useState(false)
  const [activeDesk, setActiveDesk] = useState(true)
  const sortRef = useRef();
  const activeLabel = items[activeItem].name;
  const activeItemAsc = { ...items[activeItem], type: `${items[activeItem].type} asc`};
  const activeItemDesk = items[activeItem];

  const toggleVisiblePopup = () => {
    setVisiblePopup(!visiblePopup);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.SortPopup')) {
      setVisiblePopup(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
  }, []);

  const onSelectItem = (index) => {
    setActiveItem(index);
    setVisiblePopup(false);
    if(activeAsc) {
      handleSortedByAsc({ ...items[index], type: `${items[index].type} asc`})
    }else{
    handleSortedByDesk(items[index])}
  };
  
  const handleSortedByAsc = (item) =>{
    onChange(item);
    setActiveAsc(true);
    setActiveDesk(false)
  }
  const handleSortedByDesk = (item) =>{
    onChange(item);
    setActiveAsc(false);
    setActiveDesk(true)
  }

  return (
    <div ref={sortRef} className="SortPopup">
      <div className="label">
        {title}
        <span onClick={toggleVisiblePopup}>{activeLabel}</span>
        {!sortedBy && <svg
          className={visiblePopup ? "rotated" : ""}
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
            fill="#3f73f9"
          />
        </svg>}
        {sortedBy && <div style={{position:"relative", top:3}}>
        <svg
        onClick={() =>handleSortedByAsc(activeItemAsc)}
          className="rotated"
          style={{position:"absolute"}}
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
            fill={activeAsc ? "#3f73f9" : "#42454c"}
          />
        </svg>
        <svg
        onClick={() => handleSortedByDesk(activeItemDesk)}
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
            fill={activeDesk ? "#3f73f9" : "#42454c"}
          />
        </svg>
        </div>}
      </div>
      {visiblePopup && (
        <div className="popup">
          <ul>
            {items &&
              items.map((obj, index) => (
                <li
                  onClick={() => onSelectItem(index)}
                  className={activeItem === index ? "active" : ""}
                  key={`${obj.type}_${index}`}
                >
                  {obj.name}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}
