import "./InterestsStatistics.scss";

import { Typography } from "../../ui";
//import {Audience} from "../../modules/Audience";
import { CardWhite, Accordion, AccordionChildren } from "../../widgets";

function InterestsStatistics({ data }) {
  return (
    <div className="InterestsStatistics">
      <CardWhite>
        <Typography variant="h2" color="dark-gray">
          Интересы
        </Typography>

        <div className="title-groups">
          <Typography
            className="name"
            variant="body1"
            color="dark-gray"
          ></Typography>
          <div className="items">
            <div className="item"></div>
            <div className="item">
              <Typography variant="span" color="dark-gray">
                Показано рекламы
              </Typography>
            </div>
            {/* <div className="item">
              <Typography variant="span" color="dark-gray">
                Размер сегмента
              </Typography>
            </div> */}
            <div className="item">
              <Typography variant="span" color="blue">
                Affinity Index
              </Typography>
            </div>
          </div>
        </div>
        <div className="statistics-content">
          {data && data?.length > 0 ? (
            data?.map((interest, i) => (
              <Accordion
                key={i}
                title={interest?.label}
                shown={interest?.value}
                //segment={12000*data.length}
                index={interest?.ai + "%"}
                items
              >
                {interest.categories?.map((subIt,i) => (
                  <AccordionChildren
                  key={i}
                    titleChildren={subIt?.label}
                    shownChildren={subIt?.value}
                    //segmentChildren={12000*data.length/2}
                    indexChildren={subIt?.ai + "%"}
                    subItems
                  />
                ))}
              </Accordion>
            ))
          ) : (
            <Typography variant="h2" color="dark-gray">
              У выбранных кампаний за данный период статистика по интересам
              отсутствует
            </Typography>
          )}
        </div>
      </CardWhite>
    </div>
  );
}

export default InterestsStatistics;
