import clsx from "clsx";
import "./Card.scss";
import { memo, useMemo, useState } from "react";
import { Button } from "../../ui";
import { CardDashedGraph } from "../";

import { ReactComponent as ArrowWhiteIcon } from "../../assets/images/arrow-white.svg";

function Card({ children, toggle, status }) {
  const [visibleGraph, setVisibleGraph] = useState(false);
  const toggleVisibleGraph = () => {
    setVisibleGraph(!visibleGraph);
  };
  const buttonVarian = useMemo(() => {
    switch (status) {
      case "BLOCKED": {
        return "error";
      }
      case "NOTCOMPLETE": {
        return "dark";
      }
      default:
        return "gradient";
    }
  }, [status]);

  return (
    <div className={clsx("Card", status)}>
      <div className="content">{children}</div>

      {toggle && (
        <Button variant={buttonVarian} className="toggle" onClick={toggleVisibleGraph}>
          <ArrowWhiteIcon className={visibleGraph ? "rotated" : ""} />
        </Button>
      )}

      {visibleGraph && <CardDashedGraph />}
    </div>
  );
}

export default memo(Card);
