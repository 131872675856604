import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import deLocale from "date-fns/locale/ru";
import * as React from "react";
import { useEffect } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-right-blue.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import "./DateFromTo.scss";

const curDate = new Date();
const setFirstDayOfMonth = () =>
  new Date(curDate.getFullYear(), curDate.getMonth(), 1);
const setLastDayOfMonth = () =>
  new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

function DateFromTo({
  from = setFirstDayOfMonth(),
  to = setLastDayOfMonth(),
  withoutStartDate,
  handleChange,
}) {
  const [value, setValue] = React.useState(() =>
    withoutStartDate ? ["", ""] : [from, to]
  );

  useEffect(() => handleInput(value), []);

  const handleInput = (fieldValue) => {
    setValue(fieldValue);
    handleChange(fieldValue);
  };
  return (
    <div className="DatePicker">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <DateRangePicker
          label="Advanced keyboard"
          value={value}
          mask="__.__.____"
          onChange={(newValue) => handleInput(newValue)}
          renderInput={(startProps, endProps) => (
            <>
              <input
                className="date-picker"
                ref={startProps.inputRef}
                {...startProps.inputProps}
                placeholder="Начало периода"
              />
              <Box sx={{ mx: 1 }}>
                <button
                  type="button"
                  onClick={() => handleInput([null, value[1]])}
                >
                  <CloseIcon />
                </button>
              </Box>
              <Box sx={{ mx: 1 }}>
                <ArrowIcon />
              </Box>
              <input
                className="date-picker"
                ref={endProps.inputRef}
                {...endProps.inputProps}
                placeholder="Окончание периода"
              />
              <Box sx={{ mx: 1 }}>
                <button
                  type="button"
                  onClick={() => handleInput([value[0], null])}
                >
                  <CloseIcon />
                </button>
              </Box>
            </>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DateFromTo;
