import { Link } from "react-router-dom";

import "./User.scss";

import { Typography, Dropdown } from "../../ui";

import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-big.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../redux/user/actions";
import { userSelectors } from "../../redux/user/selectors";

import { useEffect } from "react";

const User = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.all_object);
  useEffect(() => {
      dispatch(getUserInfo())
  }, [])

  return (
    <div className="User">
      <div className="info">
        <Typography variant="body1" color="dark-gray">
          {user.userInfo?.username || `${user.userInfo?.profile?.first_name ?? ''} ${user.userInfo?.profile?.surname ?? ''}`}
        </Typography>
        <Typography variant="body2" color="dark-gray">
          Client
        </Typography>
      </div>

      {/* <div className="avatar">
        <img src={Avatar} alt="avatar" />
      </div> */}

      <Dropdown button={<ArrowIcon />} closeOnClick>
        <div className="User__content">
          <Link to="/profile/contact-details">
            <Typography variant="body2" color="dark-gray">
              Профиль
            </Typography>
          </Link>
          <Link to="/balance">
            <Typography variant="body2" color="dark-gray">
              Баланс
            </Typography>
          </Link>
        </div>
      </Dropdown>
    </div>
  );
}

export default User