import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import useStatistics from  "../../data/useStatistics";
import { Typography } from "../../ui";
import {
  Card, CardDashedGraph
} from "../../widgets";
import { MetricsStatistics } from "../../widgets/MetricsStatistics";
import { StatisticsSort } from "../../widgets/Sort";
import "./Statistics.scss";
import { useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import { ReactComponent as Close } from "../../assets/images/close.svg";


const FORMIK_INITIAL_VALUES = {
  selected_campaigns: [],
  selected_goals: [],
  metric_counter: null,
  media_plan: {
    date_start: null,
    date_end: null,
  },
}

const FORMIK_INITIAL_VALUES_DATA = (camp, metric) => {
  return {
    selected_campaigns: [camp],
    selected_goals: [],
    metric_counter: metric,
    media_plan: {
      date_start: null,
      date_end: null,
    },
  }
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function Statistics() {
  const location = useLocation();
  const [err, setErr] = useState(false)
  const [modal, setModal] = useState(false)
  let campaign_id = location?.state?.campaign_id;
  let counter_id = location?.state?.counter_id;
  const [formikValues, setFormikValues] = useState(FORMIK_INITIAL_VALUES);
  let type = "day" 
  if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) < 1) type = "hour"
  // if ((formikValues.media_plan.date_end-formikValues.media_plan.date_start)/(1000 * 60 * 60 * 24) > 31) type="week"
  
  let {data, error} = useStatistics(formikValues.metric_counter,formikValues.media_plan.date_start,
      formikValues.media_plan.date_end,formikValues.selected_campaigns,formikValues.selected_goals,type);
  useEffect(()=>{
    if(error && String(error).includes('Error: {"detail":"date_from не может быть раньше текущей даты более чем на 12 месяцев"}')){
      setErr(true)
    }else{
      setErr(false)
    }
  },[error])
  useEffect(()=>{
    if (err) {
      setModal(true)
  }
  },[err])
  if (!data?.metrics) {
    data = {
      "metrics": ["shows", "reach", "clicks", "leads", "spent", "ctr", "cpc", "ecpm", "cr", "cpa"],
      "time_intervals": [],
      "data": [],
      "totals": [0, 0, 0, 0, 0, 0, 0, 0, null, null]
    }
  }
  
  // console.log("updated", formikValues)
  return (
    <>
    <Formik
      initialValues={(campaign_id) ? FORMIK_INITIAL_VALUES_DATA(campaign_id, counter_id) : FORMIK_INITIAL_VALUES}
      onSubmit={(value) => {
        console.log('export') || console.log(value)
      }}
      innerRef={formikAction => {
        if (!formikAction) {
          return;
        }
        const { values } = formikAction;
        if (
          values.selected_campaigns.length === formikValues.selected_campaigns.length
          && values.selected_campaigns.every(
            (value, index) => value === formikValues.selected_campaigns[index]
          )
          && values.media_plan.date_start === formikValues.media_plan.date_start
          && values.media_plan.date_end === formikValues.media_plan.date_end
          && values.metric_counter === formikValues.metric_counter
          && values.selected_goals.length === formikValues.selected_goals.length
          && values.selected_goals.every(
            (value, index) => value === formikValues.selected_goals[index]
          )
        ) {
          return;
        }
        setFormikValues(formikAction.values);
      }}
    >
      <Form>
        <div className="Statistics">
          <Typography variant="h1" color="dark-gray">
            Статистика
          </Typography>
          <StatisticsSort />
          <div className="line"></div>
          <div className="container">
          <MetricsStatistics data={data?.totals} />
            {/* <InterestsStatistics data={InterestData} />
            <Devices data={fiterdata2} />
            <Geography data={fiterdata2} /> */}
          </div>
          <Card>
            <CardDashedGraph title type={type} chartData={data} budget={data.totals} />
          </Card>


          {/* <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%"}}>
            <svg style={{opacity: ".7", width: "120px", height: "120px"}} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#42454c" viewBox="0 0 256 256"><path d="M160,42A86.11,86.11,0,0,0,82.43,90.88,62,62,0,1,0,72,214h88a86,86,0,0,0,0-172Zm0,160H72a50,50,0,0,1,0-100,50.67,50.67,0,0,1,5.91.35A85.61,85.61,0,0,0,74,128a6,6,0,0,0,12,0,74,74,0,1,1,74,74Zm-6-74V88a6,6,0,0,1,12,0v40a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,170,164Z"></path></svg>
            <p style={{fontSize: "20px", textAlign: "center", width: "100%", opacity: ".7"}}>Статистика временно недоступна.</p>
          </div> */}


        </div>
      </Form>
    </Formik>
    <Modal open={modal} onClose={() => setModal(false)}>
      <Box sx={style}>
        <h4 style={{width:"100%", textAlign:'center'}}>Cтатистика за более чем 12 месяца не доступна</h4>
      <Close onClick={() => setModal(false)} style={{position:'absolute', top:10,right:10, cursor:'pointer'}} />
      </Box>
    </Modal>
    </>
  );
}

export default Statistics;