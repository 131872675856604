import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import deLocale from "date-fns/locale/ru";
import { useFormikContext } from "formik";
import * as React from "react";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-right-blue.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import "./DatePicker.scss";


function DatePicker() {
  const curDate = new Date()
  const [value, setValue] = React.useState([
    new Date(curDate.getFullYear(), curDate.getMonth() - 1, curDate.getDate()),
    new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate()),
  ]);

  const formik = useFormikContext();
  React.useEffect(() => {
    if (!formik) { // todo: remove
      return;
    }
    formik.setFieldValue("media_plan.date_start", value[0]);
    formik.setFieldValue("media_plan.date_end", value[1]);
  }, [])

  return (
    <div className="DatePicker">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <DateRangePicker
          label="Advanced keyboard"
          value={value}
          mask="__.__.____"
          onChange={(newValue) => {
            if (newValue[0]) {
              formik.setFieldValue(
                "media_plan.date_start",
                newValue[0]
              );
            }
            if (newValue[1]) {
              formik.setFieldValue(
                "media_plan.date_end",
                newValue[1]
              );
            }
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <input
                className="date-picker"
                ref={startProps.inputRef}
                {...startProps.inputProps}
                placeholder="Начало периода"
              />
              <Box sx={{ mx: 1 }}>
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue(
                      "media_plan.date_start",
                      null
                    );
                    setValue([null, value[1]]);
                  }}>
                  <CloseIcon />
                </button>
              </Box>
              <Box sx={{ mx: 1 }}>
                <ArrowIcon />
              </Box>
              <input
                className="date-picker"
                ref={endProps.inputRef}
                {...endProps.inputProps}
                placeholder="Окончание периода"
              />
              <Box sx={{ mx: 1 }}>
                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue(
                      "media_plan.date_end",
                      null
                    );
                    setValue([value[0], null]);
                  }}>
                  <CloseIcon />
                </button>
              </Box>
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DatePicker;
