import "./Footer.scss";
import { PUBLIC_URL } from "../../configs/main";

export default function Footer() {
    const handleClick = () => {
        window.open(`${PUBLIC_URL}/publichnyj_dogovor-oferta.pdf`);
        };
    return (
      <div className="header-container">
        <div>Ⓒ 2023 Programmatic</div>
        <div className="oferta">
            <div>
                <button onClick={handleClick}>Оферта</button>
            </div>
            <div>
                info@programmatic.ru
            </div>
        </div>
      </div>
  );
}
