import { useEffect, useState } from "react";
import "./Payments.scss";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import EditStatusModal from "./EditStatusModal";
import {
  INIT_FILTER_VALUE,
  filterDate,
  filterIdClient,
  filterNumber,
  filterStatus,
  filterSum,
  filterType,
  filterTypePayments,
  sortPay,
} from "./filters";
import DateFromTo from "../../../widgets/DateFromTo/DateFromTo";
import { MenuItem } from "@material-ui/core";
import { API_HOST } from "../../../configs/main";
import useAdminUser from "../../../data/useAdminUser";
import { format as dateFormat } from "date-fns";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import SortPopup from "../SortPopUp/SortPopup";
import DataTable from "../Table/Datatable";

function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}

const TYPE_OF_STATUS = {
  overdue: "Просрочен",
  canceled: "Отменен",
  succeeded: "Оплачен",
  pending: "Ожидается",
};

function Payments() {
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useAdminUser();
  const [filterArr, setFilter] = useState([]);
  const [arrPayments, setPayments] = useState([]);
  const [editStatus, setStatus] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [current, setCurent] = useState({});
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/replenishments`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          console.log(data);
          setPayments(data);
          setFilter(data);
        });
      }
    });
  }, []);

  const downloadFile = async () => {
    const filters = {
      updated_at: {
        from: filtersValue.date.date_start,
        to: filtersValue.date.date_end,
      },
      type: filtersValue.typePayments,
      amount: {
        from: filtersValue.sum.start ? +filtersValue.sum.start : undefined,
        to: filtersValue.sum.start ? +filtersValue.sum.end : undefined,
      },
      number: filtersValue.number,
      status: filtersValue.status,
      user_id: filtersValue.idClient,
      super_type: filtersValue.type,
    };

    let data = removeEmpty(filters);
    if (filtersValue.date.date_start) {
      data = {
        ...data,
        updated_at: {
          from: filtersValue.date.date_start,
        },
      };
    }
    if (filtersValue.date.date_end) {
      data = {
        ...data,
        updated_at: {
          ...data.updated_at,
          to: filtersValue.date.date_end,
        },
      };
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const response = await fetch(
      `${API_HOST}/api/v1/admin/replenishments/download`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      const link = document.createElement("a");
      let filename = response.headers
        ?.get("content-disposition")
        .split("filename=")[1];
      const blob = await response.blob();
      if (filename === null) filename = "Replenishments.csv";
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleOpenStatus = (el) => {
    setStatus(true);
    setCurent(el);
  };
  const handleCloseStatus = () => {
    setStatus(false);
    setConfirmStatus(false);
  };
  const handleEditStatus = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/replenishments/${id}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        status: "succeeded",
      }),
    })
      .then((res) => {
        if (res.ok) {
          enqueueSnackbar("Статус пополнения изменен", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          enqueueSnackbar("Баланс Клиента пополнен", {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          enqueueSnackbar("Ошибка", {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        }
        setValue(INIT_FILTER_VALUE);
        setFilter((prev) =>
          prev.map((el) => {
            if (el.id === id) {
              return { ...el, status: "succeeded" };
            }
            return el;
          })
        );
        handleCloseStatus();
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      );
  };

  // const handleEditBalance = (balance_id, newSum) => {
  //     const myHeaders = new Headers();
  //     fetch(`${API_HOST}/api/v1/admin/balances/${balance_id}`, {
  //         method: 'PUT',
  //         headers: myHeaders,
  //         body: JSON.stringify({
  //             score_diff: Number(newSum)
  //         }),
  //     }).then((res) => {
  //         if (res.ok) {
  //             setPayments((prev) => prev.map((el) => {
  //                 if (el.balance_id === balance_id) {
  //                     return { ...el, sum: newSum, status: "Оплачен" }
  //                 }
  //                 return el
  //             }))
  //         }
  //     })
  //            setValue({
  //             date: {
  //                 date_end: "",
  //                 date_start: ""
  //             },
  //             typePayments: "",
  //             sum: {
  //                 start: "",
  //                 end: ""
  //             },
  //             number: "",
  //             status: "",
  //             idClient: "",
  //             type: ""
  //     })
  //     setFilter(arrPayments)
  //     handleCloseBalance()
  // }

  const handleDateChange = (value) => {
    setValue((prev) => ({
      ...prev,
      date: { date_end: value[1] ?? "", date_start: value[0] ?? "" },
    }));
  };

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "date":
            if (value === "") return acc;
            return filterDate(value, acc);
          case "typePayments":
            if (value === "") return acc;
            return filterTypePayments(value, acc);
          case "sum":
            if (value === "") return acc;
            return filterSum(value, acc);
          case "number":
            if (value === "") return acc;
            return filterNumber(value, acc);
          case "status":
            if (value === "") return acc;
            return filterStatus(value, acc);
          case "type":
            if (value === "") return acc;
            return filterType(value, acc);
          case "idClient":
            if (value === "") return acc;
            return filterIdClient(value, acc);
          case "sort":
            if (!value.length) return acc;
            return sortPay(value, acc);
          default:
            return acc;
        }
      },
      [...arrPayments]
    );
    setFilter(result);
  }, [filtersValue, arrPayments]);

  const sortChange = (item) => {
    setValue((prev) => ({ ...prev, sort: item.type }));
  };

  const columns = [
    {
      Header: "Дата пополнения",
      accessor: "updated_at",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "ID клиента",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сумма пополнения",
      accessor: "amount",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Тип",
      accessor: "type",
      maxWidth: 400,
      minWidth: 80,
      width: 200,
    },
    {
      Header: "№ Счета",
      accessor: "number",
      maxWidth: 400,
      minWidth: 80,
      width: 100,
    },
    {
      Header: "Дата генер. номера",
      accessor: "created_at",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Статус",
      accessor: "status",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сумма к оплате НДС",
      accessor: "money_vat",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Сумма к оплате без НДС",
      accessor: "money",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
    {
      Header: "Бонус",
      accessor: "bonus",
      maxWidth: 400,
      minWidth: 80,
      width: 130,
    },
  ];

  function getTableData() {
    return filterArr.map((el) => {
      return {
        updated_at: (
          <div className="td">
            {el.updated_at && el.status === "succeeded"
              ? dateFormat(Date.parse(el.updated_at), "dd/MM/yyyy")
              : el.type === "Ручное пополнение" || el.type === "Ручное списание"
              ? dateFormat(Date.parse(el.updated_at), "dd/MM/yyyy")
              : "-"}
          </div>
        ),
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span style={{ display: "flex" }}>
              {el.user_id.slice(0, 8)}
              <ContentCopy
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.user_id);
                  enqueueSnackbar("ID пользователя скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            </span>
          </div>
        ),
        amount: (
          <div className="td">
            {el.status === "succeeded"
              ? el.amount
              : el.type === "Ручное пополнение" || el.type === "Ручное списание"
              ? el.amount
              : "-"}
          </div>
        ),
        type: <div className="td">{el.type}</div>,
        number: <div className="td">{el.number}</div>,
        created_at: (
          <div className="td">
            {el.created_at
              ? dateFormat(Date.parse(el.created_at), "dd/MM/yyyy")
              : "-"}
          </div>
        ),
        status: (
          <div className="td">
            {el.status
              ? TYPE_OF_STATUS[el.status]
              : el.type === "Ручное пополнение"
              ? TYPE_OF_STATUS["succeeded"]
              : "-"}
            {["pending", "overdue"].some((status) => status === el.status) &&
              ["ADMIN", "SUPERUSER"].some((el) => el === role) &&
              el.type !== "Онлайн пополнение" && (
                <svg
                  onClick={() => handleOpenStatus(el)}
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                    fill="black"
                  />
                </svg>
              )}
          </div>
        ),
        money_vat: <div className="td">{el.money_vat}</div>,
        money: <div className="td">{el.money}</div>,
        bonus: <div className="td">{el.bonus ? el.bonus : "-"}</div>,
      };
    });
  }

  return (
    <div className="Payments" style={{ width: "100%", paddingLeft: 0, height: "95%" }}>
      <div className="filters">
        <div className="searchDate">
          <DateFromTo withoutStartDate={true} handleChange={handleDateChange} />
        </div>
        <Box sx={{ width: 125 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Тип
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Тип"
              id="demo-select-small-label"
              value={filtersValue.typePayments}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, typePayments: e.target.value }))
              }
            >
              <MenuItem value={""}>Все пополнения/списания</MenuItem>
              <MenuItem value={"Онлайн пополнение"}>Онлайн пополнение</MenuItem>
              <MenuItem value={"Пополнение по счёту"}>
                Пополнение по счету
              </MenuItem>
              <MenuItem value={"Ручное пополнение"}>Ручное пополнение</MenuItem>
              <MenuItem value={"Ручное списание"}>Ручное списание</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <div className="searchSum">
          <span className="span">Сумма пополнения</span>
          <div className="inpSum">
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  sum: { ...prev.sum, start: e.target.value },
                }))
              }
              value={filtersValue.sum.start}
              className="start"
              placeholder="от"
            />
            <input
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  sum: { ...prev.sum, end: e.target.value },
                }))
              }
              value={filtersValue.sum.end}
              className="end"
              placeholder="до"
            />
          </div>
        </div>
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, number: e.target.value }))
          }
          value={filtersValue.number}
          className="searchNum"
          placeholder="Номер счета/онлайн-оплаты"
        />
        <Box sx={{ width: 240 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Статус счета/онлайн-оплаты
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Статус счета/онлайн-оплаты"
              id="demo-select-small-label"
              value={filtersValue.status}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, status: e.target.value }))
              }
            >
              <MenuItem value={""}>Все статусы</MenuItem>
              <MenuItem value={"succeeded"}>Оплачен</MenuItem>
              <MenuItem value={"pending"}>Ожидается</MenuItem>
              <MenuItem value={"canceled"}>Отменен</MenuItem>
              <MenuItem value={"overdue"}>Просрочен</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, idClient: e.target.value }))
          }
          value={filtersValue.idClient}
          className="searchId"
          placeholder="ID клиента"
        />
        <Box sx={{ width: 390 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Фактические пополнения/Списания/Неоплаченные
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Фактические пополнения/Списания/Неоплаченные"
              id="demo-select-small-label"
              value={filtersValue.type}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, type: e.target.value }))
              }
            >
              <MenuItem value={""}>Все пополнения/списания</MenuItem>
              <MenuItem value={"deposit"}>Фактическое пополнение</MenuItem>
              <MenuItem value={"charge"}>Списания</MenuItem>
              <MenuItem value={"overdue"}>Неоплаченные</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
      <div className="downald">
        <button onClick={() => downloadFile()} type="button">
          Скачать CSV
        </button>
      </div>
      <SortPopup
        sortedBy={true}
        title="Сортировать по"
        items={[
          { name: "дате генерации номера", type: "created_at" },
          { name: "дате пополнения", type: "updated_at" },
        ]}
        onChange={sortChange}
      />
      <div style={{ width: "100%", overflowX: "auto", overflowY: "auto", height: "78%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <EditStatusModal
        editStatus={editStatus}
        handleClose={handleCloseStatus}
        confirm={confirmStatus}
        setConfirm={setConfirmStatus}
        transactoin={current}
        handleEditStatus={handleEditStatus}
      />
    </div>
  );
}

export default Payments;
