import { useMemo } from "react";
import { Table } from "../";
// import Table from '@mui/material/Table';
import { Loading } from "../../ui";


function BalanceHistoryTable({ data, isLoading }) {
  function data_change(data) {
    data = data.split('T')[0]
    let str = data.slice(8,10) + "/"+ data.slice(5,7) + "/" + data.slice(0,4)
    return str
  }
  const columns = useMemo(
    () => [
      {
        Header: "Дата",
        accessor: "created_at",
        disableGroupBy: true,
        Cell: ({ cell }) => (
          <div>{data_change(cell.row.original.created_at)}</div>
        ),
        headerStyle: {
          width: 120,
        },
        cellStyle: {
          width: 120,
        },
      },
      {
        Header: "Пополнение",
        accessor: "Who",
        disableSortBy: true,
        disableGroupBy: true,
        Cell: ({ cell }) => (
          <div>{(cell.row.original.type === "DEPOSIT") ? `+ ${cell.row.original.amount} ₽` : "-"}</div>
        ),
        headerStyle: {
          width: 120,
        },
        cellStyle: {
          width: 120,
        },
      },
      {
        Header: "Списание",
        accessor: "WhereFrom",
        disableSortBy: true,
        disableGroupBy: true,
        Cell: ({ cell }) => (
          <div>{(cell.row.original.type === "CHARGE") ? `- ${cell.row.original.amount} ₽` : "-"}</div>
        ),
        headerStyle: {
          width: 120,
        },
        cellStyle: {
          width: 120,
        },
      },
      {
        Header: "Описание",
        accessor: "Arrow",
        disableGroupBy: true,
        disableSortBy: true,
        Cell: ({ cell }) => (
          <div>{cell.row.original.description}</div>
        ),
        headerStyle: {
          width: 300,
        },
        cellStyle: {
          width: 300,
          borderRight: "none",
        },
      },
    ],
    []
  );

  return (
    <div className="BalanceHistoryTable">
      {
        isLoading ?
          <Loading /> : // todo: fix position of loader.
          data.length 
            ? <Table columns={columns} data={data} />
            : "Не найдено"  // todo: add if not find
      }
    </div>
  );
}

export default BalanceHistoryTable;
