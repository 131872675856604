import "./Devices.scss";

import { Typography } from "../../ui";
import { CardWhite, AreaChart } from "../../widgets";

function Devices({ data }) {

  console.log("AAAAAAAAAAAA")
  console.log(data)

  const mobile = []
  const desktop = []
  const tablet = []
  
  for (const key in data){
    mobile.push(data[key]['device'][0].history)
    desktop.push(data[key]['device'][1].history)
    tablet.push(data[key]['device'][2].history)
  }

  const temp_devices = [
    {
      id: 0,
      name: "Мобильные",
      history: [
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
      ],
    },
    {
      id: 1,
      name: "Десктопные",
      history: [
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
      ],
    },
    {
      id: 2,
      name: "Планшетные",
      history: [
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
        {
          value: 0,
        },
      ],
    },
  ]

  let total = 0

  for (const i in mobile) {
    for (const j in mobile[i]) {
      temp_devices[0].history[j].value += mobile[i][j].value
    }
  }

  for (const key in desktop) {
    for (let i = 0; i < desktop[key].length; i++) {
      temp_devices[1].history[i].value += desktop[key][i].value
    }
  }

  for (const key in tablet) {
    for (let i = 0; i < tablet[key].length; i++) {
      temp_devices[2].history[i].value += tablet[key][i].value
    }
  }

  for (const key in temp_devices) {
    let num = temp_devices[key].history.length
    total += temp_devices[key].history[num - 1].value
  }



  console.log("FILTERDATA")
  console.log(temp_devices)

  return (
    <div className="Devices">
      <CardWhite>
        <Typography variant="h2" color="dark-gray">
          Устройства
        </Typography>
        <Typography className="name" variant="body1" color="dark-gray">
          Авто
        </Typography>
        {temp_devices.map((device) =>
            <AreaChart 
              name={device.name} 
              value={device.history[device.history.length - 1].value == 0 ? 0 : (100 * device.history[device.history.length - 1].value / total).toFixed(0)} 
              history={device.history} 
              key={device.id}/>
          )}
      </CardWhite>
    </div>
  );
}

export default Devices;
